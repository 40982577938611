import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Service from "./pages/service";
import Price from "./pages/price";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service" element={<Service />} />
          <Route path="/price" element={<Price />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
