/* eslint-disable react/jsx-no-target-blank */
import { Link, useLocation } from "react-router-dom";

import Header from "../components/Header";
import { XIcon } from "react-share";
import { useEffect } from "react";

const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
function Price() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  const { state } = useLocation();
  const nft = state?.nft;

  let _tokenOwner = null;
  const tokenOwner = nft?.tokenOwner;
  if (tokenOwner) {
    const firstSix = tokenOwner.slice(0, 11).toString("base58");
    const lastSix = tokenOwner.slice(-11).toString("base58");
    _tokenOwner = `${firstSix}...${lastSix}`;
  }

  let _txHash = null;
  const txHash = nft?.txHash;
  if (txHash) {
    const firstSix = txHash.slice(0, 9).toString("base58");
    const lastSix = txHash.slice(-9).toString("base58");
    _txHash = `${firstSix}.........${lastSix}`;
  }

  const title = "I just minted a @Kawaaii_xyz #NFT on #SOLANA";
  const hashtags = ["NFT", "SOLANA"];

  const encodedTitle = encodeURIComponent(`${title}`);

  const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `\n\n${WEBSITE_URL}`
  )}&text=${encodedTitle}&hashtags=${hashtags}`;

  const magicEdenLink = `https://magiceden.io/item-details/${nft?.tokenMint}`;

  return (
    <>
      <Header />
      <div className="body">
        <div className="pcontainer">
          <div className="row justify-content-center justify-content-lg-start pb-4 pb-md-5">
            {nft && (
              <div className="col-11 col-md-8 col-xxl-4 col-xl-5 col-lg-6  offset-xl-1">
                <div className="body-card">
                  <Link to="/service">&#60;&#60; Back </Link>
                  <div className="body-card-wrap">
                    <div className="body-card-wrap-img">
                      <img
                        src={nft?.json?.image || "/fallbackImage.jpg"}
                        alt="The downloaded illustration of the provided NFT address."
                      />
                    </div>
                    <div className="body-card-wrap-contant">
                      <div className="body-card-wrap-contant-head">
                        <h4>{nft.name}</h4>
                        <p>
                          Owned By <b>{_tokenOwner}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-11 col-md-8 col-xxl-4 col-xl-5 col-lg-6 offset-xxl-1">
              <div className="body-pricing">
                <div className="body-pricing-card">
                  <div className="body-pricing-card-head d-flex ">
                    <div className="body-pricing-card-head-img">
                      <img src={nft?.json?.image} alt="" />
                    </div>
                    <div className="body-pricing-card-head-right">
                      <p className="fp">{nft?.name}</p>
                      <h2>{nft?.name}</h2>
                      <p>
                        Owned By <b>{_tokenOwner}</b>
                      </p>
                    </div>
                  </div>
                  <div className="body-pricing-card-item">
                    <div className="body-pricing-card-item-single d-flex ">
                      <p>Price For NFT:</p>
                      <div className="body-pricing-card-item-single-right">
                        <div className="body-pricing-card-item-single-right-wrap d-flex">
                          <h3 className=" d-flex align-items-center">{nft?.mintPrice}</h3>
                          <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_25_83)">
                              <path
                                d="M2.2743 8.87474C2.35878 8.79025 2.47495 8.74097 2.59816 8.74097H13.7714C13.9756 8.74097 14.0777 8.98738 13.9333 9.13171L11.7261 11.3389C11.6417 11.4234 11.5255 11.4727 11.4023 11.4727H0.229037C0.024863 11.4727 -0.077224 11.2263 0.0671059 11.0819L2.2743 8.87474Z"
                                fill="url(#paint0_linear_25_83)"
                              />
                              <path
                                d="M2.27381 0.633769C2.36181 0.549283 2.47798 0.5 2.59767 0.5H13.7709C13.9751 0.5 14.0772 0.746417 13.9328 0.890747L11.7257 3.09794C11.6412 3.18242 11.525 3.23171 11.4018 3.23171H0.228549C0.0243747 3.23171 -0.0777122 2.98529 0.0666176 2.84096L2.27381 0.633769Z"
                                fill="url(#paint1_linear_25_83)"
                              />
                              <path
                                d="M11.7261 4.72776C11.6417 4.64328 11.5255 4.59399 11.4023 4.59399H0.229037C0.024863 4.59399 -0.077224 4.84041 0.0671059 4.98474L2.2743 7.19193C2.35878 7.27642 2.47495 7.3257 2.59816 7.3257H13.7714C13.9756 7.3257 14.0777 7.07928 13.9333 6.93495L11.7261 4.72776Z"
                                fill="url(#paint2_linear_25_83)"
                              />
                            </g>
                            <defs>
                              <linearGradient
                                id="paint0_linear_25_83"
                                x1="12.704"
                                y1="-0.818434"
                                x2="4.97126"
                                y2="13.9929"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#00FFA3" />
                                <stop offset="1" stopColor="#DC1FFF" />
                              </linearGradient>
                              <linearGradient
                                id="paint1_linear_25_83"
                                x1="9.32236"
                                y1="-2.58378"
                                x2="1.58958"
                                y2="12.2276"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#00FFA3" />
                                <stop offset="1" stopColor="#DC1FFF" />
                              </linearGradient>
                              <linearGradient
                                id="paint2_linear_25_83"
                                x1="11.0027"
                                y1="-1.70681"
                                x2="3.2699"
                                y2="13.1046"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#00FFA3" />
                                <stop offset="1" stopColor="#DC1FFF" />
                              </linearGradient>
                              <clipPath id="clip0_25_83">
                                <rect width="14" height="10.9726" fill="white" transform="translate(0 0.5)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        {/* <p>= $2720.50</p> */}
                      </div>
                    </div>
                    {/* <div className="body-pricing-card-item-single d-flex">
                      <div className="body-pricing-card-item-single-left d-flex align-items-center">
                        <p>Gas Fee</p>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M23.814 0.558105H8.55814L0 16.5581L8.55814 31.4418H23.814L32 16.5581L23.814 0.558105Z"
                            fill="#292929"
                          />
                          <path
                            d="M16.2002 20.5573V15.7573"
                            stroke="#6A6951"
                            strokeWidth="1.8"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <circle
                            cx="16.2"
                            cy="12.7581"
                            r="0.6"
                            stroke="#6A6951"
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="body-pricing-card-item-single-right">
                        <div className="body-pricing-card-item-single-right-wrap d-flex">
                          <h3>0.00001</h3>
                          <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_25_83)">
                              <path
                                d="M2.2743 8.87474C2.35878 8.79025 2.47495 8.74097 2.59816 8.74097H13.7714C13.9756 8.74097 14.0777 8.98738 13.9333 9.13171L11.7261 11.3389C11.6417 11.4234 11.5255 11.4727 11.4023 11.4727H0.229037C0.024863 11.4727 -0.077224 11.2263 0.0671059 11.0819L2.2743 8.87474Z"
                                fill="url(#paint0_linear_25_83)"
                              />
                              <path
                                d="M2.27381 0.633769C2.36181 0.549283 2.47798 0.5 2.59767 0.5H13.7709C13.9751 0.5 14.0772 0.746417 13.9328 0.890747L11.7257 3.09794C11.6412 3.18242 11.525 3.23171 11.4018 3.23171H0.228549C0.0243747 3.23171 -0.0777122 2.98529 0.0666176 2.84096L2.27381 0.633769Z"
                                fill="url(#paint1_linear_25_83)"
                              />
                              <path
                                d="M11.7261 4.72776C11.6417 4.64328 11.5255 4.59399 11.4023 4.59399H0.229037C0.024863 4.59399 -0.077224 4.84041 0.0671059 4.98474L2.2743 7.19193C2.35878 7.27642 2.47495 7.3257 2.59816 7.3257H13.7714C13.9756 7.3257 14.0777 7.07928 13.9333 6.93495L11.7261 4.72776Z"
                                fill="url(#paint2_linear_25_83)"
                              />
                            </g>
                            <defs>
                              <linearGradient
                                id="paint0_linear_25_83"
                                x1="12.704"
                                y1="-0.818434"
                                x2="4.97126"
                                y2="13.9929"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#00FFA3" />
                                <stop offset="1" stopColor="#DC1FFF" />
                              </linearGradient>
                              <linearGradient
                                id="paint1_linear_25_83"
                                x1="9.32236"
                                y1="-2.58378"
                                x2="1.58958"
                                y2="12.2276"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#00FFA3" />
                                <stop offset="1" stopColor="#DC1FFF" />
                              </linearGradient>
                              <linearGradient
                                id="paint2_linear_25_83"
                                x1="11.0027"
                                y1="-1.70681"
                                x2="3.2699"
                                y2="13.1046"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#00FFA3" />
                                <stop offset="1" stopColor="#DC1FFF" />
                              </linearGradient>
                              <clipPath id="clip0_25_83">
                                <rect width="14" height="10.9726" fill="white" transform="translate(0 0.5)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <p>= $0.0001</p>
                      </div>
                    </div> */}
                    <div className="body-pricing-card-item-single d-flex">
                      <p>Total Price</p>
                      <div className="body-pricing-card-item-single-right">
                        <div className="body-pricing-card-item-single-right-wrap d-flex">
                          <h3>{nft?.mintPrice}</h3>
                          <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_25_83)">
                              <path
                                d="M2.2743 8.87474C2.35878 8.79025 2.47495 8.74097 2.59816 8.74097H13.7714C13.9756 8.74097 14.0777 8.98738 13.9333 9.13171L11.7261 11.3389C11.6417 11.4234 11.5255 11.4727 11.4023 11.4727H0.229037C0.024863 11.4727 -0.077224 11.2263 0.0671059 11.0819L2.2743 8.87474Z"
                                fill="url(#paint0_linear_25_83)"
                              />
                              <path
                                d="M2.27381 0.633769C2.36181 0.549283 2.47798 0.5 2.59767 0.5H13.7709C13.9751 0.5 14.0772 0.746417 13.9328 0.890747L11.7257 3.09794C11.6412 3.18242 11.525 3.23171 11.4018 3.23171H0.228549C0.0243747 3.23171 -0.0777122 2.98529 0.0666176 2.84096L2.27381 0.633769Z"
                                fill="url(#paint1_linear_25_83)"
                              />
                              <path
                                d="M11.7261 4.72776C11.6417 4.64328 11.5255 4.59399 11.4023 4.59399H0.229037C0.024863 4.59399 -0.077224 4.84041 0.0671059 4.98474L2.2743 7.19193C2.35878 7.27642 2.47495 7.3257 2.59816 7.3257H13.7714C13.9756 7.3257 14.0777 7.07928 13.9333 6.93495L11.7261 4.72776Z"
                                fill="url(#paint2_linear_25_83)"
                              />
                            </g>
                            <defs>
                              <linearGradient
                                id="paint0_linear_25_83"
                                x1="12.704"
                                y1="-0.818434"
                                x2="4.97126"
                                y2="13.9929"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#00FFA3" />
                                <stop offset="1" stopColor="#DC1FFF" />
                              </linearGradient>
                              <linearGradient
                                id="paint1_linear_25_83"
                                x1="9.32236"
                                y1="-2.58378"
                                x2="1.58958"
                                y2="12.2276"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#00FFA3" />
                                <stop offset="1" stopColor="#DC1FFF" />
                              </linearGradient>
                              <linearGradient
                                id="paint2_linear_25_83"
                                x1="11.0027"
                                y1="-1.70681"
                                x2="3.2699"
                                y2="13.1046"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#00FFA3" />
                                <stop offset="1" stopColor="#DC1FFF" />
                              </linearGradient>
                              <clipPath id="clip0_25_83">
                                <rect width="14" height="10.9726" fill="white" transform="translate(0 0.5)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        {/* <p>= $2720.50</p> */}
                      </div>
                    </div>
                    <div className="body-pricing-card-item-single d-flex justify-content-between">
                      <p>TXID Hash:</p>
                      <a
                        href={`https://explorer.solana.com/tx/${nft?.txHash}/?cluster=${process.env.REACT_APP_CLUSTER}`}
                        target="_blank"
                        className="txHash"
                      >
                        {_txHash}
                      </a>
                    </div>
                  </div>
                  <div className="custom-card-item">
                    <div className="body-pricing-card-button">
                      <a href={magicEdenLink} target="_blank" className="d-flex align-items-center justify-content-center gap-2">
                        <span>VIEW ON MAGICEDEN</span>
                      </a>
                    </div>
                  </div>

                  <div className="body-pricing-card-button mt-3">
                    <a href={twitterUrl} target="_blank" className="d-flex align-items-center justify-content-center gap-2">
                      <span>SHARE ON</span>
                      <span>
                        <XIcon size={32} spacing={10} round />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Price;
