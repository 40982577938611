import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper/modules";
import "swiper/css";

import Header from "../components/Header";
import banner1 from "../assets/img/h1.png";
import banner2 from "../assets/img/h2.png";
import banner3 from "../assets/img/h3.png";
import banner4 from "../assets/img/h4.png";
import banner5 from "../assets/img/h5.png";

import brand_img_1 from "../assets/img/brand/1.svg";
import brand_img_2 from "../assets/img/brand/2.svg";
import brand_img_3 from "../assets/img/brand/3.svg";
import brand_img_4 from "../assets/img/brand/4.svg";
import brand_shape from "../assets/img/brand/shape.svg";
import { useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import { useMetaplex } from "./useMetaplex";
import { useWallet } from "@solana/wallet-adapter-react";
import Placeholder from "../assets/img/PlaceholderImage.jpg";

const MINT_PRICE = process.env.REACT_APP_MINT_PRICE;

function Home() {
  const candyMachineAddress = new PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID);

  const { metaplex } = useMetaplex();
  const wallet = useWallet();

  // eslint-disable-next-line no-unused-vars
  const [itemsMinted, setItemsMinted] = useState(null);
  const [itemsAvailable, setItemsAvailable] = useState(null);

  useEffect(() => {
    getNFTsInfo();
  }, [wallet]);

  const [randomNFTs, setRandomNFTs] = useState([
    {
      id: 1,
      name: "KAWAAII",
      image: banner1,
      price: MINT_PRICE,
    },
    {
      id: 2,
      name: "KAWAAII",
      image: banner2,
      price: MINT_PRICE,
    },
    {
      id: 3,
      name: "KAWAAII",
      image: banner3,
      price: MINT_PRICE,
    },
    {
      id: 4,
      name: "KAWAAII",
      image: banner4,
      price: MINT_PRICE,
    },
    {
      id: 5,
      name: "KAWAAII",
      image: banner5,
      price: MINT_PRICE,
    },
  ]);

  // Function to pick n random items from an array
  const pickRandomItems = (array, count) => {
    const shuffled = array.sort(() => 0.5 - Math.random()); // Shuffle the array
    return shuffled.slice(0, count); // Get the first n items
  };

  const fetchNFTMetadata = async (uri) => {
    const response = await fetch(uri);
    const result = await response.json();
    return result;
  };

  const fetchDataForRandomNFTs = async (randomNFTs) => {
    console.log({ randomNFTs });
    const promises = randomNFTs?.map((nft) => fetchNFTMetadata(nft.uri));
    const results = await Promise.all(promises);
    return results;
  };

  const getNFTsInfo = async () => {
    //wallet not connected?
    if (!wallet.connected) {
      return;
    }

    try {
      const candyMachine = await metaplex.candyMachines().findByAddress({ address: candyMachineAddress });
      const mintedNFTs = candyMachine?.items?.filter((item) => item.minted);
      const randomNFTs = pickRandomItems(mintedNFTs, 5);
      const randomNFTsMetadata = await fetchDataForRandomNFTs(randomNFTs);
      const _randomNFTsMetadata = randomNFTsMetadata.map((nft) => ({
        name: nft.name,
        image: nft.image,
        price: MINT_PRICE,
      }));

      if (_randomNFTsMetadata?.length > 0) {
        setRandomNFTs(_randomNFTsMetadata);
      }

      setItemsMinted(candyMachine.itemsMinted.toString(10));
      setItemsAvailable(candyMachine.itemsAvailable.toString(10));
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className="parttan">
      <Header />
      <div className="banner">
        <div className="pcontainer2">
          <div className="row">
            <div className="col-12 banner-item">
              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    centeredSlides: true,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                  1441: {
                    slidesPerView: 4,
                  },
                }}
                spaceBetween={0}
                mousewheel={true}
                loop={true}
                modules={[Mousewheel]}
              >
                {randomNFTs.map((member, index) => (
                  <SwiperSlide key={index}>
                    <Link to="/service" className="banner-item-single d-block">
                      <div className="banner-item-single-img d-block">
                        <img src={member.image} alt="" />
                      </div>
                      <div className="banner-item-single-contant">
                        <div className="banner-item-single-contant-top d-flex justify-content-center flex-column align-items-center">
                          <h3>{member.name}</h3>
                          {/* <p>{member.des}</p> */}
                        </div>
                        <div className="banner-item-single-contant-bottom">
                          <div className="body-card-wrap-contant-bottom d-flex justify-content-center align-items-center">
                            <div className="body-card-wrap-contant-bottom-right d-flex justify-content-center align-items-center gap-4">
                              <div
                                style={{ margin: 0 }}
                                className="body-card-wrap-contant-bottom-left-icon d-flex align-items-center"
                              >
                                <div className="body-card-wrap-contant-bottom-left-icon-svg">
                                  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_25_43)">
                                      <path
                                        d="M2.27405 8.37474C2.35854 8.29025 2.47471 8.24097 2.59792 8.24097H13.7712C13.9753 8.24097 14.0774 8.48738 13.9331 8.63171L11.7259 10.8389C11.6414 10.9234 11.5252 10.9727 11.402 10.9727H0.228793C0.0246189 10.9727 -0.0774681 10.7263 0.0668618 10.5819L2.27405 8.37474Z"
                                        fill="url(#paint0_linear_25_43)"
                                      />
                                      <path
                                        d="M2.27405 0.133769C2.36206 0.0492834 2.47823 0 2.59792 0H13.7712C13.9753 0 14.0774 0.246417 13.9331 0.390747L11.7259 2.59794C11.6414 2.68242 11.5252 2.73171 11.402 2.73171H0.228793C0.0246189 2.73171 -0.0774681 2.48529 0.0668618 2.34096L2.27405 0.133769Z"
                                        fill="url(#paint1_linear_25_43)"
                                      />
                                      <path
                                        d="M11.7259 4.22776C11.6414 4.14328 11.5252 4.09399 11.402 4.09399H0.228793C0.0246189 4.09399 -0.0774681 4.34041 0.0668618 4.48474L2.27405 6.69193C2.35854 6.77642 2.47471 6.8257 2.59792 6.8257H13.7712C13.9753 6.8257 14.0774 6.57928 13.9331 6.43495L11.7259 4.22776Z"
                                        fill="url(#paint2_linear_25_43)"
                                      />
                                    </g>
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_25_43"
                                        x1="12.7038"
                                        y1="-1.31843"
                                        x2="4.97102"
                                        y2="13.4929"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#00FFA3" />
                                        <stop offset="1" stopColor="#DC1FFF" />
                                      </linearGradient>
                                      <linearGradient
                                        id="paint1_linear_25_43"
                                        x1="9.3226"
                                        y1="-3.08378"
                                        x2="1.58983"
                                        y2="11.7276"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#00FFA3" />
                                        <stop offset="1" stopColor="#DC1FFF" />
                                      </linearGradient>
                                      <linearGradient
                                        id="paint2_linear_25_43"
                                        x1="11.0024"
                                        y1="-2.20681"
                                        x2="3.26966"
                                        y2="12.6046"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#00FFA3" />
                                        <stop offset="1" stopColor="#DC1FFF" />
                                      </linearGradient>
                                      <clipPath id="clip0_25_43">
                                        <rect width="14" height="10.9726" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                                <span>{member.price}</span>
                              </div>
                              <p>Current Price</p>
                            </div>
                            {/* <div className="body-card-wrap-contant-bottom-right">
                              <h4>18h:35m:09s</h4>
                              <p>Remaining Time</p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="brands">
        <div className="pcontainer2">
          <div className="row">
            <div className="col-12 p-0">
              <div className="brands-title">
                <h6 className="text-white mb-0">FROM ARTISTS WHO WORKED ON:</h6>
                <img src={brand_shape} alt="" />
              </div>
              <div className="brands-list d-flex align-items-center flex-wrap">
                <div className="brands-item d-inline-block">
                  <img src={brand_img_1} alt="" />
                </div>
                <div className="brands-item d-inline-block">
                  <img src={brand_img_2} alt="" />
                </div>
                <div className="brands-item d-inline-block">
                  <img src={brand_img_3} alt="" />
                </div>
                <div className="brands-item d-inline-block">
                  <img src={brand_img_4} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="pcontainer2">
          <div className="row m-0 footer-cont justify-content-center">
            <div className="col-md-12 col-xl-9 col-lg-12 ">
              <div className="footer-item justify-content-center">
                <div className="footer-item-single">
                  <div className="footer-item-single-left">
                    <p>Last Price</p>
                    <div className="footer-item-single-left-icon d-flex">
                      <span>{MINT_PRICE}</span>
                      <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_25_863)">
                          <path
                            d="M1.94933 7.97574C2.02174 7.90333 2.12132 7.86108 2.22692 7.86108H11.804C11.979 7.86108 12.0665 8.0723 11.9428 8.19601L10.0509 10.0879C9.9785 10.1603 9.87892 10.2025 9.77332 10.2025H0.196248C0.0212414 10.2025 -0.0662617 9.99133 0.0574496 9.86762L1.94933 7.97574Z"
                            fill="url(#paint0_linear_25_863)"
                          />
                          <path
                            d="M1.94908 0.912145C2.02452 0.839728 2.12409 0.797485 2.22668 0.797485H11.8037C11.9788 0.797485 12.0663 1.0087 11.9425 1.13241L10.0507 3.02429C9.97825 3.09671 9.87868 3.13895 9.77307 3.13895H0.196004C0.0209973 3.13895 -0.0665059 2.92773 0.0572055 2.80402L1.94908 0.912145Z"
                            fill="url(#paint1_linear_25_863)"
                          />
                          <path
                            d="M10.0509 4.4213C9.9785 4.34888 9.87892 4.30664 9.77332 4.30664H0.196248C0.0212414 4.30664 -0.0662617 4.51786 0.0574496 4.64157L1.94933 6.53344C2.02174 6.60586 2.12132 6.6481 2.22692 6.6481H11.804C11.979 6.6481 12.0665 6.43689 11.9428 6.31318L10.0509 4.4213Z"
                            fill="url(#paint2_linear_25_863)"
                          />
                        </g>
                        <defs>
                          <linearGradient
                            id="paint0_linear_25_863"
                            x1="10.8891"
                            y1="-0.332689"
                            x2="4.26101"
                            y2="12.3628"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#00FFA3" />
                            <stop offset="1" stopColor="#DC1FFF" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_25_863"
                            x1="7.9907"
                            y1="-1.84576"
                            x2="1.3626"
                            y2="10.8497"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#00FFA3" />
                            <stop offset="1" stopColor="#DC1FFF" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_25_863"
                            x1="9.4308"
                            y1="-1.09405"
                            x2="2.8027"
                            y2="11.6014"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#00FFA3" />
                            <stop offset="1" stopColor="#DC1FFF" />
                          </linearGradient>
                          <clipPath id="clip0_25_863">
                            <rect width="12" height="9.40508" fill="white" transform="translate(0 0.797485)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <div className="footer-item-single-right">
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 0L9.00918 6.84L13.9282 12L7 10.32L0.0717969 12L4.99082 6.84L7 0Z" fill="#5A227D" />
                    </svg>
                  </div>
                </div>
                <div className="footer-item-single">
                  <div className="footer-item-single-left">
                    <p>Status</p>
                    <h3>Public Mint</h3>
                  </div>
                  <div className="footer-item-single-right">
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 0L9.00918 6.84L13.9282 12L7 10.32L0.0717969 12L4.99082 6.84L7 0Z" fill="#5A227D" />
                    </svg>
                  </div>
                </div>
                <div className="footer-item-single">
                  <div className="footer-item-single-left">
                    <p>Actual Price</p>
                    <div className="footer-item-single-left-icon d-flex">
                      <span>{MINT_PRICE}</span>
                      <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_25_863)">
                          <path
                            d="M1.94933 7.97574C2.02174 7.90333 2.12132 7.86108 2.22692 7.86108H11.804C11.979 7.86108 12.0665 8.0723 11.9428 8.19601L10.0509 10.0879C9.9785 10.1603 9.87892 10.2025 9.77332 10.2025H0.196248C0.0212414 10.2025 -0.0662617 9.99133 0.0574496 9.86762L1.94933 7.97574Z"
                            fill="url(#paint0_linear_25_863)"
                          />
                          <path
                            d="M1.94908 0.912145C2.02452 0.839728 2.12409 0.797485 2.22668 0.797485H11.8037C11.9788 0.797485 12.0663 1.0087 11.9425 1.13241L10.0507 3.02429C9.97825 3.09671 9.87868 3.13895 9.77307 3.13895H0.196004C0.0209973 3.13895 -0.0665059 2.92773 0.0572055 2.80402L1.94908 0.912145Z"
                            fill="url(#paint1_linear_25_863)"
                          />
                          <path
                            d="M10.0509 4.4213C9.9785 4.34888 9.87892 4.30664 9.77332 4.30664H0.196248C0.0212414 4.30664 -0.0662617 4.51786 0.0574496 4.64157L1.94933 6.53344C2.02174 6.60586 2.12132 6.6481 2.22692 6.6481H11.804C11.979 6.6481 12.0665 6.43689 11.9428 6.31318L10.0509 4.4213Z"
                            fill="url(#paint2_linear_25_863)"
                          />
                        </g>
                        <defs>
                          <linearGradient
                            id="paint0_linear_25_863"
                            x1="10.8891"
                            y1="-0.332689"
                            x2="4.26101"
                            y2="12.3628"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#00FFA3" />
                            <stop offset="1" stopColor="#DC1FFF" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_25_863"
                            x1="7.9907"
                            y1="-1.84576"
                            x2="1.3626"
                            y2="10.8497"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#00FFA3" />
                            <stop offset="1" stopColor="#DC1FFF" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_25_863"
                            x1="9.4308"
                            y1="-1.09405"
                            x2="2.8027"
                            y2="11.6014"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#00FFA3" />
                            <stop offset="1" stopColor="#DC1FFF" />
                          </linearGradient>
                          <clipPath id="clip0_25_863">
                            <rect width="12" height="9.40508" fill="white" transform="translate(0 0.797485)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <div className="footer-item-single-right">
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 0L9.00918 6.84L13.9282 12L7 10.32L0.0717969 12L4.99082 6.84L7 0Z" fill="#5A227D" />
                    </svg>
                  </div>
                </div>
                <div className="footer-item-single">
                  <div className="footer-item-single-left">
                    <p>Kawaaii Left</p>
                    <h3>{itemsAvailable && itemsMinted ? 2082 - itemsMinted + "/" + 2082 : "NA / 2082"}</h3>
                  </div>
                  <div className="footer-item-single-right">
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 0L9.00918 6.84L13.9282 12L7 10.32L0.0717969 12L4.99082 6.84L7 0Z" fill="#5A227D" />
                    </svg>
                  </div>
                </div>
                <div className="footer-item-single">
                  <div className="footer-item-single-left">
                    <p>Mint your Kawaaii</p>
                    <h3>Mint Kawaaii</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-md-5">
              <div className="footer-button">
                <Link className="text-uppercase" to="/service">
                  MINT Kawaaii
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
