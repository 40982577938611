/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-catch */
/* eslint-disable react/prop-types */
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Placeholder from "../assets/img/placeholder.svg";
import priceIcon from "../assets/img/price-icon.svg";
import { useMetaplex } from "./useMetaplex";
import { useState, useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { getMerkleProof, toPublicKey } from "@metaplex-foundation/js";
import { Audio } from "react-loader-spinner";

const DEFAULT_GUARD_NAME = null;
const MINT_PRICE = process.env.REACT_APP_MINT_PRICE;
function Service() {
  const allowList = [
    {
      groupName: "OG",
      wallets: [],
    },
    {
      groupName: "WL",
      wallets: [],
    },
  ];

  // Set your expiration time here (replace with your actual timestamp)
  // const expirationTime = new Date("2024-05-31T23:59:59").getTime();

  const navigate = useNavigate();
  const { metaplex } = useMetaplex();
  const wallet = useWallet();

  //   const [nft, setNft] = useState(null);

  const [isLive, setIsLive] = useState(true);
  const [hasEnded, setHasEnded] = useState(false);
  const [addressGateAllowedToMint, setAddressGateAllowedToMint] = useState(true);
  const [mintLimitReached, setMintLimitReached] = useState(false);
  const [hasEnoughSol, setHasEnoughSol] = useState(true);
  const [hasEnoughSolForFreeze, setHasEnoughSolForFreeze] = useState(true);
  const [nftGatePass, setNftGatePass] = useState(true);
  const [missingNftBurnForPayment, setMissingNftBurnForPayment] = useState(false);
  const [missingNftForPayment, setMissingNftForPayment] = useState(false);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [noSplTokenToBurn, setNoSplTokenToBurn] = useState(false);
  const [splTokenGatePass, setSplTokenGatePass] = useState(true);
  const [noSplTokenToPay, setNoSplTokenToPay] = useState(false);
  const [noSplTokenForFreeze, setNoSplTokenForFreeze] = useState(false);
  const [disableMint, setDisableMint] = useState(true);
  const [isMaxRedeemed, setIsMaxRedeemed] = useState(false);
  const [mintingInProgress, setMintingInProgress] = useState(false);

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(DEFAULT_GUARD_NAME);
  const [candyMachineLoaded, setCandyMachineLoaded] = useState(false);
  const [itemsMinted, setItemsMinted] = useState(0);
  const [itemsAvailable, setItemsAvailable] = useState(0);

  const candyMachineAddress = new PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID);
  let candyMachine;
  let walletBalance;

  const getGuard = (selectedGroup, candyMachine) => {
    try {
      console.log(selectedGroup);
      if (selectedGroup == DEFAULT_GUARD_NAME) {
        return candyMachine?.candyGuard.guards;
      }

      const group = candyMachine.candyGuard.groups.find((group) => {
        return group.label == selectedGroup;
      });

      console.log(group);

      if (!group) {
        console.error(selectedGroup + " group not found. Defaulting to public");
        return candyMachine.candyGuard.guards;
      }

      return group.guards;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  useEffect(() => {
    if (mintingInProgress) {
      return;
    }

    checkEligibility();
  }, [selectedGroup, mintingInProgress]);

  const addListener = async () => {
    // add a listener to reevaluate if the user is allowed to mint if startDate is reached
    // const slot = await metaplex.connection.getSlot();
    // const solanaTime = await metaplex.connection.getBlockTime(slot);
    // const startDateGuard = getGuard(selectedGroup, candyMachine).startDate;
    // if (startDateGuard != null) {
    //   const candyStartDate = startDateGuard.date.toString(10);
    //   const refreshTime = candyStartDate - solanaTime.toString(10);
    //   if (refreshTime > 0) {
    //     setTimeout(() => checkEligibility(), refreshTime * 1000);
    //   }
    // }
    // // also reevaluate eligibility after endDate is reached
    // const endDateGuard = getGuard(selectedGroup, candyMachine).endDate;
    // if (endDateGuard != null) {
    //   const candyEndDate = endDateGuard.date.toString(10);
    //   const refreshTime = solanaTime.toString(10) - candyEndDate;
    //   if (refreshTime > 0) {
    //     setTimeout(() => checkEligibility(), refreshTime * 1000);
    //   }
    // }
  };

  const checkEligibility = async () => {
    //wallet not connected?
    if (!wallet.connected) {
      setDisableMint(true);
      return;
    }

    try {
      // read candy machine state from chain
      console.log(metaplex);
      candyMachine = await metaplex.candyMachines().findByAddress({ address: candyMachineAddress });

      setCandyMachineLoaded(true);

      const guardGroups = candyMachine.candyGuard.groups.map((group) => {
        return group.label;
      });
      if (groups.join(",") != guardGroups.join(",")) {
        setGroups(guardGroups);
        if (selectedGroup === DEFAULT_GUARD_NAME) {
          setSelectedGroup(guardGroups[0]);
        }
      }

      setItemsMinted(candyMachine.itemsMinted.toString(10));
      setItemsAvailable(candyMachine.itemsAvailable.toString(10));

      // enough items available?
      if (candyMachine.itemsMinted.toString(10) - candyMachine.itemsAvailable.toString(10) >= 0) {
        console.error("not enough items available");
        setDisableMint(true);
        setIsSoldOut(true);
        return;
      }

      // guard checks have to be done for the relevant guard group! Example is for the default groups defined in Part 1 of the CM guide
      const guard = getGuard(selectedGroup, candyMachine);

      // Calculate current time based on Solana BlockTime which the on chain program is using - startTime and endTime guards will need that
      // const slot = await metaplex.connection.getSlot();
      // const solanaTime = await metaplex.connection.getBlockTime(slot);

      // if (guard.startDate != null) {
      //   const candyStartDate = guard.startDate.date.toString(10);
      //   if (solanaTime < candyStartDate) {
      //     console.error("startDate: CM not live yet");
      //     setDisableMint(true);
      //     setIsLive(false);
      //     return;
      //   }
      // }

      // if (guard.endDate != null) {
      //   const candyEndDate = guard.endDate.date.toString(10);
      //   if (solanaTime > candyEndDate) {
      //     console.error("endDate: CM not live anymore");
      //     setDisableMint(true);
      //     setHasEnded(true);
      //     return;
      //   }
      // }

      if (guard.addressGate != null) {
        if (metaplex.identity().publicKey.toBase58() != guard.addressGate.address.toBase58()) {
          console.error("addressGate: You are not allowed to mint");
          setDisableMint(true);
          setAddressGateAllowedToMint(false);
          return;
        }
      }

      if (guard.mintLimit != null) {
        const mitLimitCounter = metaplex.candyMachines().pdas().mintLimitCounter({
          id: guard.mintLimit.id,
          user: metaplex.identity().publicKey,
          candyMachine: candyMachine.address,
          candyGuard: candyMachine.candyGuard.address,
        });
        //Read Data from chain
        const mintedAmountBuffer = await metaplex.connection.getAccountInfo(mitLimitCounter, "processed");
        let mintedAmount;
        if (mintedAmountBuffer != null) {
          mintedAmount = mintedAmountBuffer.data.readUintLE(0, 1);
        }
        if (mintedAmount != null && mintedAmount >= guard.mintLimit.limit) {
          console.error("mintLimit: mintLimit reached!");
          setDisableMint(true);
          setMintLimitReached(true);
          return;
        }
      }

      if (guard.solPayment != null) {
        walletBalance = await metaplex.connection.getBalance(metaplex.identity().publicKey);

        const costInLamports = guard.solPayment.amount.basisPoints.toString(10);

        if (costInLamports > walletBalance) {
          console.error("solPayment: Not enough SOL!");
          setDisableMint(true);
          setHasEnoughSol(false);
          return;
        }
      }

      if (guard.freezeSolPayment != null) {
        walletBalance = await metaplex.connection.getBalance(metaplex.identity().publicKey);

        const costInLamports = guard.freezeSolPayment.amount.basisPoints.toString(10);

        if (costInLamports > walletBalance) {
          console.error("freezeSolPayment: Not enough SOL!");
          setDisableMint(true);
          setHasEnoughSolForFreeze(false);
          return;
        }
      }

      if (guard.nftGate != null) {
        const ownedNfts = await metaplex.nfts().findAllByOwner({ owner: metaplex.identity().publicKey });
        const nftsInCollection = ownedNfts.filter((obj) => {
          return (
            obj.collection?.address.toBase58() === guard.nftGate.requiredCollection.toBase58() &&
            obj.collection?.verified === true
          );
        });
        if (nftsInCollection.length < 1) {
          console.error("nftGate: The user has no NFT to pay with!");
          setDisableMint(true);
          setNftGatePass(false);
          return;
        }
      }

      if (guard.nftBurn != null) {
        const ownedNfts = await metaplex.nfts().findAllByOwner({ owner: metaplex.identity().publicKey });
        const nftsInCollection = ownedNfts.filter((obj) => {
          return (
            obj.collection?.address.toBase58() === guard.nftBurn.requiredCollection.toBase58() &&
            obj.collection?.verified === true
          );
        });
        if (nftsInCollection.length < 1) {
          console.error("nftBurn: The user has no NFT to pay with!");
          setDisableMint(true);
          setMissingNftBurnForPayment(true);
          return;
        }
      }

      if (guard.nftPayment != null) {
        const ownedNfts = await metaplex.nfts().findAllByOwner({ owner: metaplex.identity().publicKey });
        const nftsInCollection = ownedNfts.filter((obj) => {
          return (
            obj.collection?.address.toBase58() === guard.nftPayment.requiredCollection.toBase58() &&
            obj.collection?.verified === true
          );
        });
        if (nftsInCollection.length < 1) {
          console.error("nftPayment: The user has no NFT to pay with!");
          setDisableMint(true);
          setMissingNftForPayment(true);
          return;
        }
      }

      if (guard.redeemedAmount != null) {
        if (guard.redeemedAmount.maximum.toString(10) <= candyMachine.itemsMinted.toString(10)) {
          console.error("redeemedAmount: Too many NFTs have already been minted!");
          setDisableMint(true);
          setIsMaxRedeemed(true);
          return;
        }
      }

      if (guard.tokenBurn != null) {
        const ata = await metaplex
          .tokens()
          .pdas()
          .associatedTokenAccount({ mint: guard.tokenBurn.mint, owner: metaplex.identity().publicKey });
        const balance = await metaplex.connection.getTokenAccountBalance(ata);
        if (balance < guard.tokenBurn.amount.basisPoints.toNumber()) {
          console.error("tokenBurn: Not enough SPL tokens to burn!");
          setDisableMint(true);
          setNoSplTokenToBurn(true);
          return;
        }
      }

      if (guard.tokenGate != null) {
        const ata = await metaplex
          .tokens()
          .pdas()
          .associatedTokenAccount({ mint: guard.tokenGate.mint, owner: metaplex.identity().publicKey });
        const balance = await metaplex.connection.getTokenAccountBalance(ata);
        if (balance < guard.tokenGate.amount.basisPoints.toNumber()) {
          console.error("tokenGate: Not enough SPL tokens!");
          setDisableMint(true);
          setSplTokenGatePass(false);
          return;
        }
      }

      if (guard.tokenPayment != null) {
        console.log("df", new PublicKey(guard.tokenPayment.destinationAta));
        const ata = guard.tokenPayment.destinationAta;
        const balance = await metaplex.connection.getTokenAccountBalance(ata);
        if (balance < guard.tokenPayment.amount.basisPoints.toNumber()) {
          console.error("tokenPayment: Not enough SPL tokens to pay!");
          setDisableMint(true);
          setNoSplTokenToPay(true);
          return;
        }
        if (guard.freezeTokenPayment != null) {
          const ata = await metaplex
            .tokens()
            .pdas()
            .associatedTokenAccount({ mint: guard.freezeTokenPayment.mint, owner: metaplex.identity().publicKey });
          const balance = await metaplex.connection.getTokenAccountBalance(ata);
          if (balance < guard.tokenPayment.amount.basisPoints.toNumber()) {
            console.error("freezeTokenPayment: Not enough SPL tokens to pay!");
            setDisableMint(true);
            setNoSplTokenForFreeze(true);
            return;
          }
        }
      }

      //good to go! Allow them to mint
      setDisableMint(false);
      setIsLive(true);
      setHasEnded(false);
      setAddressGateAllowedToMint(true);
      setMintLimitReached(false);
      setHasEnoughSol(true);
      setHasEnoughSolForFreeze(true);
      setNftGatePass(true);
      setMissingNftBurnForPayment(false);
      setMissingNftForPayment(false);
      setIsSoldOut(false);
      setNoSplTokenToBurn(false);
      setSplTokenGatePass(true);
      setNoSplTokenToPay(false);
      setNoSplTokenForFreeze(false);
      setIsMaxRedeemed(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onClick = async () => {
    setMintingInProgress(true);

    let _nft = null;
    try {
      await mintingGroupAllowlistCheck();

      const group = selectedGroup == DEFAULT_GUARD_NAME ? undefined : selectedGroup;
      const mintResponse = await metaplex.candyMachines().mint({
        candyMachine,
        collectionUpdateAuthority: candyMachine.authorityAddress,
        ...(group && { group }),
      });

      const owner = new PublicKey(mintResponse.nft.token.ownerAddress._bn).toBase58();
      const tokenMint = new PublicKey(mintResponse.nft.mint.address._bn).toBase58();
      const signature = mintResponse.response.signature;

      //   Extract nft from mintResponse and fetch uri data
      const nft = mintResponse.nft;
      const response = await fetch(nft.uri);
      const result = await response.json();

      //   Reconstruct nft object
      _nft = { ...nft, json: result, tokenOwner: owner, txHash: signature, mintPrice: MINT_PRICE, tokenMint: tokenMint };
    } catch (e) {
      console.log(e);
    } finally {
      setMintingInProgress(false);
      if (_nft) {
        navigate("/price", { state: { nft: _nft } });
      }
    }
  };

  const mintingGroupAllowlistCheck = async () => {
    const group = selectedGroup == DEFAULT_GUARD_NAME ? undefined : selectedGroup;

    const guard = getGuard(selectedGroup, candyMachine);
    if (!guard.allowList) {
      return;
    }

    const groupDetails = allowList.find((group) => {
      return group.groupName == selectedGroup;
    });

    if (!groupDetails) {
      throw new Error(`Cannot mint, as no list of accounts provided for group ${selectedGroup} with allowlist settings enabled`);
    }

    const mintingWallet = metaplex.identity().publicKey.toBase58();

    try {
      await metaplex.candyMachines().callGuardRoute({
        candyMachine,
        guard: "allowList",
        settings: {
          path: "proof",
          merkleProof: getMerkleProof(groupDetails.wallets, mintingWallet),
        },
        ...(group && { group }),
      });
    } catch (e) {
      console.error(
        `MerkleTreeProofMismatch: Wallet ${mintingWallet} is not allowlisted for minting in the group ${selectedGroup}`
      );
      throw e;
    }
  };

  // if it's the first time we are processing this function with a connected wallet we read the CM data and add Listeners
  if (wallet.connected && candyMachine === undefined) {
    (async () => {
      // read candy machine data to get the candy guards address
      await checkEligibility();
      // Add listeners to refresh CM data to reevaluate if minting is allowed after the candy guard updates or startDate is reached
      // addListener();
    })();
  }
  return (
    <>
      <Header />
      <div className="body">
        <div className="pcontainer">
          <div className="row justify-content-center pb-4 pb-lg-5">
            <div className="col-10 col-sm-7 col-md-6 col-lg-5 col-xl-5 col-xxl-4">
              <div className="body-card">
                <Link to="/">&#60;&#60; Back </Link>
                <div className="body-card-wrap">
                  <div className="body-card-wrap-img" style={{ height: "20em" }}>
                    <img style={{ width: "100%", height: "100%" }} src={Placeholder} alt="" />
                  </div>
                  <div className="body-card-wrap-contant d-flex justify-content-center flex-column align-items-center">
                    <div className="body-card-wrap-contant-head">
                      <h4>KAWAAII</h4>
                      <p>{/* Owned By <b>Chadx.Eth</b> */}</p>
                    </div>
                    <div className="body-card-wrap-contant-bottom d-flex justify-content-center align-items-center">
                      <div className="body-card-wrap-contant-bottom-right d-flex justify-content-center align-items-center gap-4">
                        <div
                          style={{ margin: 0 }}
                          className="body-card-wrap-contant-bottom-left-icon d-flex align-items-center justify-content-center"
                        >
                          <div className="body-card-wrap-contant-bottom-left-icon-svg">
                            <img src={priceIcon} alt="" />
                          </div>
                          <span>{MINT_PRICE}</span>
                        </div>
                        <p>Current Price</p>
                      </div>
                      {/* <div className="body-card-wrap-contant-bottom-right">
                        <h4>
                          <Countdown date={expirationTime} />
                        </h4>
                        <p>Remaining Time</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-11 col-md-8 col-xl-5 col-lg-6 offset-xxl-1">
              <div className="body-sale">
                {candyMachineLoaded && (
                  <div className="key-indicators">
                    {isLive && !hasEnded && <h1>Minting Live!</h1>}
                    {isLive && hasEnded && <h1>Minting End!</h1>}
                    {!isLive && <h1>Minting Not Live!</h1>}
                  </div>
                )}
                <div className="body-sale-item">
                  <div className="body-sale-item-single">
                    <div className="body-sale-item-single-top">
                      <p></p>
                      <h3>PUBLIC SALE/2082</h3>
                      <p>Public sale is open to everyone. The price of the mint is {MINT_PRICE} SOL. First come first serve.</p>
                      <p>Good Luck!</p>
                    </div>
                    <div className="body-sale-item-single-bottom d-flex">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
                {mintingInProgress && (
                  <div className="body-mintbtn d-flex justify-content-center">
                    <Audio height="80" width="80" radius="9" color="green" ariaLabel="loading" wrapperStyle wrapperClass />
                  </div>
                )}
                {wallet.connected && !disableMint && !mintingInProgress && (
                  <div className="body-mintbtn d-flex justify-content-center">
                    <Link className="rc2" onClick={onClick} disabled={disableMint}>
                      MINT KAWAAII
                    </Link>
                  </div>
                )}
                {!wallet.connected && (
                  <div className="d-flex flex-column justify-content-center key-indicators">
                    <h1 className="rc2">Please Connect Wallet</h1>
                  </div>
                )}
                {candyMachineLoaded && (
                  <div className="d-flex flex-column justify-content-center key-indicators">
                    {!addressGateAllowedToMint && <h1 className="rc2">Wallet address not allowed to mint</h1>}
                    {mintLimitReached && <h1 className="rc2">Minting limit reached</h1>}
                    {(!hasEnoughSol || !hasEnoughSolForFreeze) && <h1 className="rc2">Insufficient SOL balance</h1>}
                    {(!nftGatePass || missingNftBurnForPayment || missingNftForPayment) && (
                      <h1 className="rc2">Missing required NFT for minting</h1>
                    )}
                    {isSoldOut && <h1 className="rc2">Sold out!</h1>}
                    {isMaxRedeemed && (
                      <h1 className="rc2">Maximum amount of NFTs allowed to be minted has already been minted!</h1>
                    )}
                    {(!splTokenGatePass || noSplTokenToBurn || noSplTokenToPay || noSplTokenForFreeze) && (
                      <h1 className="rc2">Missing required SPL token for minting</h1>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Service;
