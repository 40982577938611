import React from "react";
import ReactDOM from "react-dom/client";
import WalletConnection from "./WalletConnection";
import "../src/assets/scss/style.scss";
import "../src/assets/img/x-image.png";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <WalletConnection />
  </React.StrictMode>
);
