import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.png";
// import  from "../assets/img/logo.png";
import Sniper from "../assets/img/sniper.png";
import TwitterIcon from "../assets/img/x1.png";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

function Header() {
  return (
    <>
      <div className="header">
        <div className="pcontainer">
          <div className="row">
            <div className="col-12 col-sm-3 mt-2 header-custom">
              <Link style={{ width: "9rem" }} to="/" className="header-logo">
                <img src={Logo} alt="" />
              </Link>
            </div>
            <div className="col-12 col-sm-9 text-end header-right">
              <div className="connect-wallet">
                <WalletMultiButton />
              </div>
              <div className="">
                <Link
                  style={{ width: "2.5rem", marginRight: "1rem" }}
                  to="https://raritysniper.com/nft-drops-calendar"
                  className="header-logo"
                  target="_blank"
                >
                  <img src={Sniper} alt="" />
                </Link>
                <Link
                  style={{ width: "2rem", marginRight: "1rem" }}
                  to="https://twitter.com/Kawaaii_xyz"
                  className="header-logo"
                  target="_blank"
                >
                  <img src={TwitterIcon} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
